import './App.css';

import React from 'react';
import logo from './logo.svg';

function App() {
  return (
    <div className="App">
      <header className="App-header">
          <img src={logo} alt="logo" style={{height: "40vh", width: "100vw"}} />
        <p className="construction-message">
          This website is under construction, expect it to be online at most in Feb 22
        </p>
      </header>
      <body className="socialContainer">
        <ul className="socialListing">
          <li className="social"><a href="https://www.linkedin.com/in/caiorrs/" rel="noopener noreferrer" target="_blank">LinkedIn</a></li>
          <li className="social"><a href="https://www.instagram.com/caiorrs.dev/" rel="noopener noreferrer" target="_blank">Instagram</a></li>
          <li className="social"><a href="https://www.youtube.com/user/caiorrs" rel="noopener noreferrer" target="_blank">Youtube</a></li>
          <li className="social"><a href="mailto:caiorrs@gmail.com" title="caiorrs@gmail.com" rel="noopener noreferrer" target="_blank">Email</a></li>
        </ul> 
      </body>
    </div>
  );
}

export default App;
